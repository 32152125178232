.dates {
  margin: 20px 0 10px;
  color: #9c9c9c;
  font-size: 12px;
}

.dates-bottom {
  display: flex;
  justify-content: space-between;
}

.footer {
  margin: 0 18px;
}

.sidebar {
  width: 550px;
}

.colorIcon {
  display: inline-block;
  width: 6px;
  height: 6px;
  border: 2px solid transparent;
  border-radius: 50%;
}

.iconButton {
  border-radius: 3px 0 0 3px;
  border: 1px solid #bababa;
  background-color: #f2f2f2;
  width: 34px;
  height: 34px;
  padding: 0;
}

.arrowDown {
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.dropdown > button {
  cursor: pointer;
}
