@import "./theme";

.iw-notifications-container {
  z-index: 10001;
  position: fixed;
  top: 60px !important;
  width: 350px !important;

  /* stylelint-disable selector-type-no-unknown */
  iw-notification {
    position: relative;
  }

  .iw-notification {
    overflow: hidden !important;

    min-height: 30px !important;
    padding: 15px 35px 15px 45px !important;

    background: #fff !important;
    border-radius: 3px;

    .icon {
      left: 5px;
      padding: 0;
    }

    &.has-icon:before {
      content: "";
      display: block;
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 27px;
    }

    &.success {
      border: 1px solid var(--tm-green);
      box-shadow: 0 0 10px var(--tm-green);

      &:before {
        background: center no-repeat var(--tm-green);
      }
    }

    &.info {
      border: 1px solid var(--tm-blue);
      box-shadow: 0 0 10px var(--tm-blue);

      &:before {
        background: center no-repeat var(--tm-blue);
      }
    }

    &.error {
      border: 1px solid var(--tm-red);
      box-shadow: 0 0 10px var(--tm-red);

      &:before {
        background: center no-repeat var(--tm-red);
      }
    }

    &.warn {
      border: 1px solid var(--tm-orange);
      box-shadow: 0 0 10px var(--tm-orange);

      &:before {
        background: center no-repeat var(--tm-orange);
      }
    }

    .iw-notification-title,
    .iw-notification-content {
      display: block;
      overflow: hidden;
      padding: 0 !important;
      margin: 0 !important;
      color: #1f1f1f;
      font-size: 14px;
      line-height: 110%;
      word-break: break-word;
    }

    .iw-notification-title {
      margin-bottom: 4px !important;

      color: #1f1f1f !important;
      font-weight: bold;
      line-height: 120%;

      &:empty {
        display: none;
      }
    }
    .iw-notification-content-bold {
      font-weight: bold;
    }
  }
}
