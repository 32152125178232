// css variables hacks
:root {
  --formElBorderColorHover: var(--tm-green);
  --formElBorderColorFocus: var(--tm-green);
  --formElBgColor: var(--tm-white);
  --formLabelColor: var(--tm-light-gray);
  --formLabelTxtPosition: flex-end;
  --inputBgColor: var(--tm-white);
  --inputBorderColorHover: var(--tm-green);
  --inputBorderColorFocus: var(--tm-green);
  --inputErrorBorderColor: var(--tm-red);
  --inputErrorShadow: inset 0 0 2px var(--tm-red);
  --inputBoxShadowFocus: inset 0 0 2px var(--tm-green);
  --errorMsgTxtColor: var(--tm-errorColor);
  --selectBorderColorFocus: var(--tm-green);
  --selectBorderColorHover: var(--tm-green);
  --selectPopoverTBorderRadius: var(--tm-borderDefaultRadius);
  --selectPopoverBBorderRadius: var(--tm-borderDefaultRadius);
  --selectBgColorHover: var(--tm-white);
  --selectFontColorHover: #333;
  --popoverBorderRadius: var(--tm-borderDefaultRadius);
  --popoverBackgroundColor: var(--tm-white);
  --errorMsgBgColor: var(--tm-white);
  --errorMsgPadding: 0;
  --buttonBackGround: #eee;
  --buttonHoverBackGround: #e6e6e6;
  --buttonSuccessHoverBackGround: #067339;
  --buttonHoverTextColor: #333;
  --buttonWidth: var(--buttonHeight);
  --modalMaxWidth: auto;
  --loadingSuccessColor: #fff;
}
//other hacks
.iw-form-row {
  grid-template-columns: calc(var(--formLabelWidth) + 10px) auto !important;
}

button.iw-button.iconButton {
  height: var(--buttonHeight);
  width: var(--buttonWidth);
  /* stylelint-disable max-line-length */
  padding:
    var(--buttonPaddingsPaddingTop) var(--buttonPaddingsPaddingRight) var(--buttonPaddingsPaddingBottom)
    var(--buttonPaddingsPaddingRight);
  /* stylelint-enable max-line-length */
  box-sizing: border-box;
  border: var(--buttonBorderWidth);
  border-radius: var(--buttonBorderRadius);
  border-color: var(--buttonBorderColor);
  background-color: var(--buttonBackGround);
  color: var(--buttonTextColor);
  font-size: var(--buttonSmallLabelFontSize);
  font-family: var(--buttonLabelFontFamily);
  line-height: var(--buttonLabelLineHeight);
  text-decoration: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  outline: none;
}
button.iw-button.iconButton:hover {
  border: #bababa solid 1px;
  background-color: var(--buttonHoverBackGround);
  color: #333;
}
.iw-button.iw-color-success:active,
.iw-button.iw-color-success:hover {
  color: #fff !important;
}

button.iw-button:hover .iw-button-spinner {
  border-color: var(--buttonSuccessBackGround);
  border-bottom-color: transparent;
}

.iw-button.iw-color-success .iw-button-spinner:hover {
  animation: loadingHover 1s infinite linear alternate !important;
  animation-delay: 0.5s !important;

  &:before {
    animation: loadingHover 1s infinite linear alternate !important;
    animation-delay: 0s !important;
  }
  &:after {
    animation: loadingHover 1s infinite linear alternate !important;
    animation-delay: 1s !important;
  }
}

.popover-inner {
  margin: 0;
  padding: var(--popoverPadding);
  box-sizing: border-box;

  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 0 7px rgb(0 0 0 / 20%);
  background-color: #fff;
  background-clip: padding-box;

  font-size: 14px;
  text-align: left;

  list-style: none;
}

.popover-inner[disabled="true"] {
  opacity: 0.4;
  pointer-events: none;
}

.iw-dropdown-button:after {
  content: "";

  position: absolute;
  top: 15px;
  right: 5px;

  border: 4px solid;
  border-bottom: none;
  border-color: #777 transparent;
}

.popover-inner button {
  display: block;

  width: 100%;
  padding: 8px 18px;
  box-sizing: border-box;

  border: none;
  background: 0 0;

  color: #333;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  white-space: nowrap;
  text-align: left;
}
.popover-inner button:hover {
  background-color: #f2f2f2;
  color: #333;
}

.popover-inner button:disabled {
  background: 0 0;
  cursor: not-allowed;
}

.iw-tree-node.iw-tree-node-focused {
  background-color: #e1e1e1;
}
.iw-tree-node:hover {
  background-color: #e1e1e1 !important;
}

// надо втащить "sanitize.css" чтобы было меньше разницы со стилями платформы
:where(svg:not([fill])) {
  fill: currentColor;
}

.iw-checkbox-indicator {
  box-sizing: border-box;
}
