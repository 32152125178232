input.tm-input {
  height: 34px;
  border: 1px solid #ccc;
  border-radius: 3px;
  color: #000;
  font-size: 15px;
  font-family: Roboto, sans-serif;
  padding: 0 12px;
}

.tm-input:focus {
  border: 1px solid #008c46;
  box-shadow: inset 0 0 2px #008c46;
  outline: none;
}

input.tm-input-invalid {
  border-color: #d65c4f;
  color: #d65c4f;
}

input.search-input {
  width: 230px;
  padding-left: 25px;
  background: url("./../assets/icons/svg/search.svg") 5px 5px no-repeat;
}

.search-input.width-auto {
  width: auto;
}

.input-group-title {
  font-size: 14px;
  font-weight: 700;
  margin: 0 0 15px;
}
