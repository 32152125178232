@import "ag-grid-community/styles/ag-grid.min.css";
/* stylelint-disable selector-type-no-unknown */

.agGridContainer {
  overflow: auto;
  flex-shrink: 1000;
  height: 100%;
}

ag-grid-angular {
  .ag-row.loading {
    opacity: 0.3;
  }

  .ag-bl-normal {
    width: 100%;
    box-sizing: border-box;
  }

  .ag-header-row {
    border-bottom: 1px solid #ddd;
  }

  .ag-header-cell {
    display: flex;
    align-items: center;

    margin: 0;
    padding: 0;

    border-right: none;
    border-bottom: 1px solid #ddd;
    background: white;

    color: rgb(51 51 51 / 50%);
    font-size: 14px;
    font-family: Roboto, Helvetica, sans-serif;
    text-align: left;
    line-height: 100%;
  }

  .ag-header-cell-text {
    padding: 0 6px;
  }

  .ag-row {
    border-bottom: 1px solid #ddd;
  }

  .ag-row-cell {
    padding: 6px;

    border-bottom: 1px solid #ddd;
  }

  .ag-cell {
    display: flex;
    align-items: center;

    padding: 0 10px 0 5px;
    min-height: 30px;
    box-sizing: border-box;

    font-size: 14px;

    outline: none;
    overflow: hidden;
  }

  .ag-cell a {
    color: #007fff;

    text-decoration: none;
  }

  .ag-cell a:hover {
    color: #06c;
  }

  .ag-cell.icon {
    position: relative;
    top: 1px;
    margin-top: -3px;
  }

  .ag-cell.tag__color {
    display: inline-block;
  }

  .ag-cell.edit_cell {
    background-color: transparent;
    border: none;

    color: transparent;

    cursor: pointer;
  }

  .ag-cell.edit_cell::placeholder {
    color: transparent;
  }

  .ag-row-selected {
    background-color: #dcdcdc;
  }

  .ag-ltr .ag-header-cell-resize {
    order: 3;
    right: 3px;
    cursor: col-resize;
    margin-left: auto;
  }

  [ref="eSortAsc"] > span:before,
  [ref="eSortDesc"] > span:before {
    content: "";
  }
  [ref="eSortAsc"] > span,
  [ref="eSortDesc"] > span {
    margin-left: 4px;
    border: 4px solid;
    border-color: #333 transparent;
  }

  [ref="eSortAsc"] > span {
    border-top: none;
  }

  [ref="eSortDesc"] > span {
    border-bottom: none;
  }

  .ag-icon-grip {
    width: 10px;
    height: 20px;
    margin: 0 10px 0 0;
    background: url("../assets/icons/png/dragndrop.png") center no-repeat;
    cursor: grab;
    display: flex;
  }

  .ag-icon-grip:before {
    content: "";
  }

  .ag-tooltip {
    background-color: white;
    border-radius: 2px;
    padding: 5px;
    border-width: 1px;
    border-style: solid;
    border-color: #545454;
  }

  .ag-cell-label-container:focus {
    outline: none;
  }
}

.deleted-item {
  color: #d65c4f;
}

.show-spaces {
  white-space: pre-wrap;
}

ag-grid-angular .ag-header-cell.greyTableGridHeader {
  background-color: #eee;
}

.ag-header-cell:not(.ag-column-hover):not(.ag-header-cell-moving).lightGreenTableGridHeader,
.ag-header-cell:not(.ag-column-resizing) + .ag-header-cell:not(.ag-column-hover).lightGreenTableGridHeader {
  background-color: #6eae60;
  color: #fff;
  &:hover {
    background-color: #6eae60;
  }
}

.ag-header-cell:not(.ag-column-hover):not(.ag-header-cell-moving).greenTableGridHeader,
.ag-header-cell:not(.ag-column-resizing) + .ag-header-cell:not(.ag-column-hover).greenTableGridHeader {
  background-color: #007d32;
  color: #fff;
  &:hover {
    background-color: #007d32;
  }
}
