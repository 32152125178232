// copypaste parsed.scss from shared

/* stylelint-disable */
:root {

  // button

  --buttonDisabledOpacity: 0.5;
  --buttonSuccessHoverBackGround: #4683C9;
  --buttonSuccessBackGround: #008C46;
  --buttonSuccessTextColor: #FFF;
  --buttonHoverBackGround: #008C46;
  --buttonHoverTextColor: #FFF;
  --buttonHoverBorderColor: transparent;
  --buttonLabelFontFamily: "Roboto";
  --buttonLabelFontSize: 16px;
  --buttonLabelFontWeight: 400;
  --buttonLabelLineHeight: 22px;
  --buttonBorderRadius: 2px;
  --buttonBorderWidth: 1px solid;
  --buttonBorderColor: #CCC;
  --buttonHeight: 36px;
  --buttonSmallHeight: 34px;
  --buttonSmallIconBorderColor: transparent;
  --buttonSmallLabelFontFamily: "Roboto";
  --buttonSmallLabelFontSize: 15px;
  --buttonSmallLabelFontWeight: 400;
  --buttonSmallLabelLineHeight: 17.578125px;
  --buttonSmallestHeight: 30px;
  --buttonSmallestLabelFontFamily: "Roboto";
  --buttonSmallestLabelFontSize: 15px;
  --buttonSmallestLabelFontWeight: 400;
  --buttonSmallestLabelLineHeight: 17.578125px;
  --buttonFilterHoverTextColor: #4683C9;
  --buttonFilterHoverBorderWidth: 1px solid;
  --buttonFilterHoverBorderColor: #008C46;
  --buttonFilterHeight: 24px;
  --buttonFilterLabelFontFamily: "Roboto";
  --buttonFilterLabelFontSize: 15px;
  --buttonFilterLabelFontWeight: 400;
  --buttonFilterLabelLineHeight: 17.578125px;
  --buttonTextColor: #333;
  --buttonWidgetEventsHoverTextColor: #4683C9;
  --buttonWidgetEventsHeight: 34px;
  --buttonWidgetHoverTextColor: #4683C9;
  --buttonWidgetHeight: 24px;
  --buttonWidgetWidth: 24px;
  --buttonWidgetTextColor: #333;
  --buttonBackGround: #FFF;
  --buttonPaddingsPaddingLeft: 12px;
  --buttonPaddingsPaddingBottom: 9px;
  --buttonPaddingsPaddingRight: 12px;
  --buttonPaddingsPaddingTop: 9px;

  // buttonToggle

  --buttonToggleSelectedBorderWidth: 1px solid;
  --buttonToggleSelectedBorderColor: #008C46;
  --buttonToggleDisabledOpacity: 0.5;
  --buttonToggleBorderRadius: 0px;
  --buttonToggleBorderWidth: 1px solid;
  --buttonToggleBorderColor: #CCC;
  --buttonToggleLabelFontFamily: "Roboto";
  --buttonToggleLabelFontSize: 16px;
  --buttonToggleLabelFontWeight: 400;
  --buttonToggleLabelLineHeight: 22px;
  --buttonToggleTextColor: #333;
  --buttonToggleBackGround: #FFF;
  --buttonToggleHeight: 36px;
  --buttonToggleIconButtonToggleHeight: 32px;
  --buttonTogglePaddingsPaddingLeft: 12px;
  --buttonTogglePaddingsPaddingBottom: 9px;
  --buttonTogglePaddingsPaddingRight: 12px;
  --buttonTogglePaddingsPaddingTop: 9px;

  // checkbox

  --checkboxDisabledOpacity: 0.5;
  --checkboxReadonlyBackGround: #EEE;
  --checkboxSelectedHideHoverBorderWidth: 1px solid;
  --checkboxSelectedHideHoverBorderColor: #CF4534;
  --checkboxSelectedHideTextColor: #CF4534;
  --checkboxSelectedCheckHoverBorderWidth: 1px solid;
  --checkboxSelectedCheckHoverBorderColor: #008C46;
  --checkboxSelectedCheckTextColor: #008C46;
  --checkboxHoverBackGround: #EEE;
  --checkboxBackGround: #FFF;
  --checkboxHeight: 20px;
  --checkboxWidth: 20px;
  --checkboxBorderWidth: 1px solid;
  --checkboxBorderColor: #CCC;
  --checkboxBorderRadius: 2px;
  --checkboxTextColor: #333;
  --checkboxLabelFontFamily: "Roboto";
  --checkboxLabelFontSize: 15px;
  --checkboxLabelFontWeight: 400;
  --checkboxLabelLineHeight: 17.578125px;

  // input

  --inputErrorBorderWidth: 1px solid;
  --inputErrorBorderColor: #CF4534;
  --inputErrorTextColor: #CF4534;
  --inputErrorLabelFontFamily: "Roboto";
  --inputErrorLabelFontSize: 15px;
  --inputErrorLabelFontWeight: 400;
  --inputErrorLabelLineHeight: 17.578125px;
  --inputDisabledFilledBorderWidth: 1px solid;
  --inputDisabledFilledBorderColor: #E2E2E2;
  --inputDisabledFilledTextColor: #969696;
  --inputDisabledBackGround: #EEE;
  --inputDisabledTextColor: #333;
  --inputDisabledOpacity: 0.5;
  --inputReadonlyBackGround: #EEE;
  --inputReadonlyTextColor: #333;
  --inputSelectedBorderWidth: 1px solid;
  --inputSelectedBorderColor: #008C46;
  --inputSelectedBackGround: #EEE;
  --inputSelectedTextColor: #333;
  --inputHoverBorderWidth: 1px solid;
  --inputHoverBorderColor: #008C46;
  --inputScrollSmallestBackGround: #CCC;
  --inputBorderRadius: 2px;
  --inputBackGround: #EEE;
  --inputPaddingsPaddingLeft: 11px;
  --inputPaddingsPaddingBottom: 1px;
  --inputPaddingsPaddingRight: 11px;
  --inputPaddingsPaddingTop: 1px;
  --inputBorderWidth: 1px solid;
  --inputBorderColor: #EEE;
  --inputHeight: 36px;
  --inputTextColor: #333;
  --inputLabelFontFamily: "Roboto";
  --inputLabelFontSize: 16px;
  --inputLabelFontWeight: 400;
  --inputLabelLineHeight: 22px;

  // radio

  --radioDisabledOpacity: 0.5;
  --radioReadonlyBackGround: #EEE;
  --radioSelectedTextColor: #008C46;
  --radioSelectedHoverBackGround: #008C46;
  --radioHoverBorderWidth: 1px solid;
  --radioHoverBorderColor: #008C46;
  --radioHoverBackGround: #EEE;
  --radioHoverTextColor: #008C46;
  --radioBackGround: #FFF;
  --radioBorderWidth: 1px solid;
  --radioBorderColor: #CCC;
  --radioWidth: 17px;
  --radioHeight: 17px;
  --radioTextColor: #333;
  --radioLabelFontFamily: "Roboto";
  --radioLabelFontSize: 15px;
  --radioLabelFontWeight: 400;
  --radioLabelLineHeight: 17.578125px;

  // timeInput

  --timeInputDisabledOpacity: 0.5;
  --timeInputSelectedBorderWidth: 1px solid;
  --timeInputSelectedBorderColor: #008C46;
  --timeInputHoverBorderWidth: 1px solid;
  --timeInputHoverBorderColor: #008C46;
  --timeInputBackGround: #EEE;
  --timeInputPaddingsPaddingLeft: 10px;
  --timeInputPaddingsPaddingBottom: 9px;
  --timeInputPaddingsPaddingRight: 32px;
  --timeInputPaddingsPaddingTop: 9px;
  --timeInputBorderWidth: 1px;
  --timeInputBorderColor: #EEE;
  --timeInputBorderRadus: 2px;
  --timeInputHeight: 36px;
  --timeInputWidth: 80px;
  --timeInputBorderRadius: 2px;
  --timeInputTextColor: #333;
  --timeInputLabelFontFamily: "Roboto";
  --timeInputLabelFontSize: 15px;
  --timeInputLabelFontWeight: 400;
  --timeInputLabelLineHeight: 17.578125px;

  // select

  --selectDisabledFilledBorderWidth: 1px solid;
  --selectDisabledFilledBorderColor: #EEE;
  --selectDisabledFilledTextColor: #333;
  --selectDisabledBackGround: #EEE;
  --selectDisabledTextColor: #333;
  --selectOpenedHoverBackGround: #DEDEDE;
  --selectOpenedBackGround: #EEE;
  --selectOpenedBorderWidth: 1px solid;
  --selectOpenedBorderColor: #008C46;
  --selectOpenedScrollSmallHoverBackGround: #DEDEDE;
  --selectOpenedScrollSmallBackGround: #EEE;
  --selectOpenedScrollSmallSliderBackGround: #EEE;
  --selectOpenedOptionLabelFontFamily: "Roboto";
  --selectOpenedOptionLabelFontSize: 15px;
  --selectOpenedOptionLabelFontWeight: 400;
  --selectOpenedOptionLabelLineHeight: 17.578125px;
  --selectOpenedOptionTextColor: #333;
  --selectOpenedEmptyTextColor: #CCC;
  --selectOpenedEmptyLabelFontFamily: "Roboto";
  --selectOpenedEmptyLabelFontSize: 15px;
  --selectOpenedEmptyLabelFontWeight: 400;
  --selectOpenedEmptyLabelLineHeight: 17.578125px;
  --selectOpenedErrorTextColor: #CF4534;
  --selectOpenedErrorLabelFontFamily: "Roboto";
  --selectOpenedErrorLabelFontSize: 15px;
  --selectOpenedErrorLabelFontWeight: 400;
  --selectOpenedErrorLabelLineHeight: 17.578125px;
  --selectFilledHoverTagBackGround: #EEE;
  --selectFilledHoverBackGround: #DEDEDE;
  --selectFilledBorderWidth: 1px solid;
  --selectFilledBorderColor: #CCC;
  --selectFilledBackGround: #EEE;
  --selectFilledTextColor: #333;
  --selectFilledTagBackGround: #CCC;
  --selectFilledTagTextColor: #333;
  --selectHoverBackGround: #00752F;
  --selectHoverBorderWidth: 1px solid;
  --selectHoverBorderColor: #00752F;
  --selectBorderRadius: 2px;
  --selectBorderWidth: 1px solid;
  --selectBorderColor: #EEE;
  --selectHeight: 36px;
  --selectBackGround: #EEE;
  --selectTextColor: #333;
  --selectLabelFontFamily: "Roboto";
  --selectLabelFontSize: 16px;
  --selectLabelFontWeight: 400;
  --selectLabelLineHeight: 22px;
  --selectPaddingsPaddingLeft: 12px;
  --selectPaddingsPaddingBottom: 9px;
  --selectPaddingsPaddingRight: 36px;
  --selectPaddingsPaddingTop: 9px;

  // scrollSmall

  --scrollSmallHoverBackGround: #DEDEDE;
  --scrollSmallBackGround: #EEE;
  --scrollSmallSliderBackGround: #EEE;

  // modals

  --modalsBackGround: #FFF;
  --modalsWidth: 480px;
  --modalsPaddingsPaddingLeft: 24px;
  --modalsPaddingsPaddingBottom: 24px;
  --modalsPaddingsPaddingRight: 24px;
  --modalsPaddingsPaddingTop: 24px;
  --modalsTextColor: #333;
  --modalsLabelFontFamily: "Roboto";
  --modalsLabelFontSize: 16px;
  --modalsLabelFontWeight: 500;
  --modalsLabelLineHeight: 18.75px;
  --modalsDividerPaddingsPaddingLeft: 0;
  --modalsDividerPaddingsPaddingBottom: 24px;
  --modalsDividerPaddingsPaddingRight: 0;
  --modalsDividerPaddingsPaddingTop: 24px;
  --modalsContentTextColor: #333;
  --modalsContentLabelFontFamily: "Roboto";
  --modalsContentLabelFontSize: 15px;
  --modalsContentLabelFontWeight: 400;
  --modalsContentLabelLineHeight: 17.578125px;
  --modalsButtonDisabledOpacity: 0.5;
  --modalsButtonSuccessHoverBackGround: #4683C9;
  --modalsButtonSuccessBackGround: #008C46;
  --modalsButtonSuccessTextColor: #FFF;
  --modalsButtonHoverBackGround: #008C46;
  --modalsButtonHoverTextColor: #FFF;
  --modalsButtonHoverBorderColor: transparent;
  --modalsButtonLabelFontFamily: "Roboto";
  --modalsButtonLabelFontSize: 16px;
  --modalsButtonLabelFontWeight: 400;
  --modalsButtonLabelLineHeight: 22px;
  --modalsButtonBorderRadius: 2px;
  --modalsButtonBorderWidth: 1px solid;
  --modalsButtonBorderColor: #CCC;
  --modalsButtonHeight: 36px;
  --modalsButtonSmallHeight: 34px;
  --modalsButtonSmallIconBorderColor: transparent;
  --modalsButtonSmallLabelFontFamily: "Roboto";
  --modalsButtonSmallLabelFontSize: 15px;
  --modalsButtonSmallLabelFontWeight: 400;
  --modalsButtonSmallLabelLineHeight: 17.578125px;
  --modalsButtonSmallestHeight: 30px;
  --modalsButtonSmallestLabelFontFamily: "Roboto";
  --modalsButtonSmallestLabelFontSize: 15px;
  --modalsButtonSmallestLabelFontWeight: 400;
  --modalsButtonSmallestLabelLineHeight: 17.578125px;
  --modalsButtonFilterHoverTextColor: #4683C9;
  --modalsButtonFilterHoverBorderWidth: 1px solid;
  --modalsButtonFilterHoverBorderColor: #008C46;
  --modalsButtonFilterHeight: 24px;
  --modalsButtonFilterLabelFontFamily: "Roboto";
  --modalsButtonFilterLabelFontSize: 15px;
  --modalsButtonFilterLabelFontWeight: 400;
  --modalsButtonFilterLabelLineHeight: 17.578125px;
  --modalsButtonTextColor: #333;
  --modalsButtonWidgetEventsHoverTextColor: #4683C9;
  --modalsButtonWidgetEventsHeight: 34px;
  --modalsButtonWidgetHoverTextColor: #4683C9;
  --modalsButtonWidgetHeight: 24px;
  --modalsButtonWidgetWidth: 24px;
  --modalsButtonWidgetTextColor: #333;
  --modalsButtonBackGround: #FFF;
  --modalsButtonPaddingsPaddingLeft: 12px;
  --modalsButtonPaddingsPaddingBottom: 9px;
  --modalsButtonPaddingsPaddingRight: 12px;
  --modalsButtonPaddingsPaddingTop: 9px;

  // calendar

  --calendarOpenedCellHoverBackGround: #EEE;
  --calendarOpenedCellTodayTextColor: #00752F;
  --calendarOpenedCellTodayLabelFontFamily: "Roboto";
  --calendarOpenedCellTodayLabelFontSize: 16px;
  --calendarOpenedCellTodayLabelFontWeight: 500;
  --calendarOpenedCellTodayLabelLineHeight: 18.75px;
  --calendarOpenedCellSelectedTextColor: #FFF;
  --calendarOpenedCellSelectedBackGround: #008C46;
  --calendarOpenedCellSelectedHeight: 32px;
  --calendarOpenedCellSelectedWidth: 32px;
  --calendarOpenedCellDisabledTextColor: #F3F3F3;
  --calendarOpenedCellLabelFontFamily: "Roboto";
  --calendarOpenedCellLabelFontSize: 15px;
  --calendarOpenedCellLabelFontWeight: 400;
  --calendarOpenedCellLabelLineHeight: 17.578125px;
  --calendarOpenedCellTextColor: #333;
  --calendarOpenedTextColor: #333;
  --calendarOpenedLabelFontFamily: "Roboto";
  --calendarOpenedLabelFontSize: 16px;
  --calendarOpenedLabelFontWeight: 500;
  --calendarOpenedLabelLineHeight: 18.75px;

  // charts

  --chartsAxisSelectedTextColor: #131AA4;
  --chartsAxisSelectedBorderColor: transparent;
  --chartsAxisBorderColor: transparent;
  --chartsAxisLabelFontFamily: "Roboto";
  --chartsAxisLabelFontSize: 11px;
  --chartsAxisLabelFontWeight: 500;
  --chartsAxisLabelLineHeight: 14px;
  --chartsAxisTextColor: #333;
  --chartsScrollBigSliderBackGround: #FFF;
  --chartsScrollBigSliderBorderWidth: 1px solid;
  --chartsScrollBigSliderBorderColor: #848484;
  --chartsScrollBigSliderBorderRadius: 2px;
  --chartsScrollBigBackGround: #EEE;
  --chartsLabelFontFamily: "Roboto";
  --chartsLabelFontSize: 16px;
  --chartsLabelFontWeight: 500;
  --chartsLabelLineHeight: 18.75px;
  --chartsTextColor: #333;

  // dateInput

  --dateInputSelectDisabledFilledBorderWidth: 1px solid;
  --dateInputSelectDisabledFilledBorderColor: #EEE;
  --dateInputSelectDisabledFilledTextColor: #333;
  --dateInputSelectDisabledBackGround: #EEE;
  --dateInputSelectDisabledTextColor: #333;
  --dateInputSelectOpenedHoverBackGround: #DEDEDE;
  --dateInputSelectOpenedBackGround: #EEE;
  --dateInputSelectOpenedBorderWidth: 1px solid;
  --dateInputSelectOpenedBorderColor: #008C46;
  --dateInputSelectOpenedScrollSmallHoverBackGround: #DEDEDE;
  --dateInputSelectOpenedScrollSmallBackGround: #EEE;
  --dateInputSelectOpenedScrollSmallSliderBackGround: #EEE;
  --dateInputSelectOpenedOptionLabelFontFamily: "Roboto";
  --dateInputSelectOpenedOptionLabelFontSize: 15px;
  --dateInputSelectOpenedOptionLabelFontWeight: 400;
  --dateInputSelectOpenedOptionLabelLineHeight: 17.578125px;
  --dateInputSelectOpenedOptionTextColor: #333;
  --dateInputSelectOpenedEmptyTextColor: #CCC;
  --dateInputSelectOpenedEmptyLabelFontFamily: "Roboto";
  --dateInputSelectOpenedEmptyLabelFontSize: 15px;
  --dateInputSelectOpenedEmptyLabelFontWeight: 400;
  --dateInputSelectOpenedEmptyLabelLineHeight: 17.578125px;
  --dateInputSelectOpenedErrorTextColor: #CF4534;
  --dateInputSelectOpenedErrorLabelFontFamily: "Roboto";
  --dateInputSelectOpenedErrorLabelFontSize: 15px;
  --dateInputSelectOpenedErrorLabelFontWeight: 400;
  --dateInputSelectOpenedErrorLabelLineHeight: 17.578125px;
  --dateInputSelectFilledHoverTagBackGround: #EEE;
  --dateInputSelectFilledHoverBackGround: #DEDEDE;
  --dateInputSelectFilledBorderWidth: 1px solid;
  --dateInputSelectFilledBorderColor: #CCC;
  --dateInputSelectFilledBackGround: #EEE;
  --dateInputSelectFilledTextColor: #333;
  --dateInputSelectFilledTagBackGround: #CCC;
  --dateInputSelectFilledTagTextColor: #333;
  --dateInputSelectHoverBackGround: #00752F;
  --dateInputSelectHoverBorderWidth: 1px solid;
  --dateInputSelectHoverBorderColor: #00752F;
  --dateInputSelectBorderRadius: 2px;
  --dateInputSelectBorderWidth: 1px solid;
  --dateInputSelectBorderColor: #EEE;
  --dateInputSelectHeight: 36px;
  --dateInputSelectBackGround: #EEE;
  --dateInputSelectTextColor: #333;
  --dateInputSelectLabelFontFamily: "Roboto";
  --dateInputSelectLabelFontSize: 16px;
  --dateInputSelectLabelFontWeight: 400;
  --dateInputSelectLabelLineHeight: 22px;
  --dateInputSelectPaddingsPaddingLeft: 12px;
  --dateInputSelectPaddingsPaddingBottom: 9px;
  --dateInputSelectPaddingsPaddingRight: 36px;
  --dateInputSelectPaddingsPaddingTop: 9px;
  --dateInputCalendarOpenedCellHoverBackGround: #EEE;
  --dateInputCalendarOpenedCellTodayTextColor: #00752F;
  --dateInputCalendarOpenedCellTodayLabelFontFamily: "Roboto";
  --dateInputCalendarOpenedCellTodayLabelFontSize: 16px;
  --dateInputCalendarOpenedCellTodayLabelFontWeight: 500;
  --dateInputCalendarOpenedCellTodayLabelLineHeight: 18.75px;
  --dateInputCalendarOpenedCellSelectedTextColor: #FFF;
  --dateInputCalendarOpenedCellSelectedBackGround: #008C46;
  --dateInputCalendarOpenedCellSelectedHeight: 32px;
  --dateInputCalendarOpenedCellSelectedWidth: 32px;
  --dateInputCalendarOpenedCellDisabledTextColor: #F3F3F3;
  --dateInputCalendarOpenedCellLabelFontFamily: "Roboto";
  --dateInputCalendarOpenedCellLabelFontSize: 15px;
  --dateInputCalendarOpenedCellLabelFontWeight: 400;
  --dateInputCalendarOpenedCellLabelLineHeight: 17.578125px;
  --dateInputCalendarOpenedCellTextColor: #333;
  --dateInputCalendarOpenedTextColor: #333;
  --dateInputCalendarOpenedLabelFontFamily: "Roboto";
  --dateInputCalendarOpenedLabelFontSize: 16px;
  --dateInputCalendarOpenedLabelFontWeight: 500;
  --dateInputCalendarOpenedLabelLineHeight: 18.75px;

  // tooltip

  --tooltipBorderRadius: 4px;
  --tooltipPaddingsPaddingLeft: 4px;
  --tooltipPaddingsPaddingBottom: 4px;
  --tooltipPaddingsPaddingRight: 4px;
  --tooltipPaddingsPaddingTop: 4px;
  --tooltipTextColor: #FFF;
  --tooltipLabelFontFamily: "Roboto";
  --tooltipLabelFontSize: 11px;
  --tooltipLabelFontWeight: 500;
  --tooltipLabelLineHeight: 14px;

  // popover

  --popoverBorderRadius: 4px;
  --popoverPaddingsPaddingLeft: 12px;
  --popoverPaddingsPaddingBottom: 8px;
  --popoverPaddingsPaddingRight: 12px;
  --popoverPaddingsPaddingTop: 8px;
  --popoverTextColor: #333;
  --popoverLabelFontFamily: "Roboto";
  --popoverLabelFontSize: 15px;
  --popoverLabelFontWeight: 400;
  --popoverLabelLineHeight: 17.578125px;

  // notifications

  --notificationsPaddingsPaddingLeft: 40px;
  --notificationsPaddingsPaddingBottom: 12px;
  --notificationsPaddingsPaddingRight: 0;
  --notificationsPaddingsPaddingTop: 12px;
  --notificationsBorderRadius: 4px;
  --notificationsHeadTextColor: #FFF;
  --notificationsHeadLabelFontFamily: "Roboto";
  --notificationsHeadLabelFontSize: 15px;
  --notificationsHeadLabelFontWeight: 400;
  --notificationsHeadLabelLineHeight: 17.578125px;
  --notificationsLabelFontFamily: "Roboto";
  --notificationsLabelFontSize: 16px;
  --notificationsLabelFontWeight: 500;
  --notificationsLabelLineHeight: 18.75px;

  // hint

  --hintErrorTextColor: #CF4534;
  --hintBorderRadius: 4px;
  --hintPaddingsPaddingLeft: 16px;
  --hintPaddingsPaddingBottom: 12px;
  --hintPaddingsPaddingRight: 16px;
  --hintPaddingsPaddingTop: 12px;
  --hintTextColor: #E8954D;
  --hintLabelFontFamily: "Roboto";
  --hintLabelFontSize: 14px;
  --hintLabelFontWeight: 500;
  --hintLabelLineHeight: 16.40625px;
}

