// copypaste theme.scss from shared

/* stylelint-disable */
:root {
  --formElHeight: 36px;
  --formElPadding: 8px 12px;

  --formElBorderWidth: 1px;
  --formElBorderRadius: 2px;

  --formElBgColor: #eff1f1;
  --formElBgColorHover: #eff1f1;
  --formElBgColorSelected: #e0e2e2;
  --formElBgColorSelectedHover: #cccece;
  --formElBgColorFocus: #fff;

  --formElBorderColor: #e5e5e5;
  --formElBorderColorHover: #008c46;
  --formElBorderColorFocus: #008c46;

  --formElTxtColor: #222;
  --formElPlaceholderColor: rgba(0, 0, 0, 0.5);

  --formElFont: 'Roboto', sans-serif;
  --formElFontSize: 15px;
  --formElLineheight: 20px;

  --formToolbarBorder: rgba(0, 0, 0, 0.1);

  --formLabelWidth: 150px;
  --formLabelMaxHeight: 34px;
  --formLabelTxtPosition: flex-start;
  --formLabelColor: #333;

  // Scrollbar
  --scrollWidth: 8px;
  --scrollColor: none;
  --scrollShadow: none;
  --scrollRadius: 0;
  --scrollTrackColor: rgba(0, 0, 0, 0.1);
  --scrollTrackShadow: none;
  --scrollTrackRadius: 0;
  --scrollTrackBorder: none;
  --scrollThumbColor: rgba(0, 0, 0, 0.2);
  --scrollThumbShadow: none;
  --scrollThumbRadius: 0;
  --scrollThumbBorder: none;

  // Input
  --inputHeight: var(--formElHeight);
  --inputHeightSmall: calc(var(--inputHeight) - 4px);
  --inputHeightSmallest: calc(var(--inputHeight) - 8px);
  --inputPadding: 0 11px;

  --inputBorder: 1px solid;
  --inputBorderRadius: 2px;
  --inputBgColor: #eff1f1;
  --inputBgColorHover: #f3f4f4;
  --inputBgColorFocus: #fff;
  --inputBorderColor: transparent;
  --inputBorderColorHover: #008c46;
  --inputBorderColorFocus: #008c46;
  --inputErrorBorderColor: #e85c5d;
  --inputErrorBgColor: #fff;
  --inputErrorShadow: none;
  --inputSelectionColor: #fcf7ba;
  --inputBoxShadow: none;
  --inputBoxShadowFocus: none;

  --inputFontSize: 15px;
  --inputFontColor: #000;

  // Error message
  --errorMsgTxtColor: #d54344;
  --errorMsgBgColor: #fceeee;
  --errorMsgFontSize: 15px;
  --errorMsgPadding: 6px 11px;

  // Select
  --selectPaddingTop: 8px;
  --selectPaddingRight: 6px;
  --selectPaddingBottom: 8px;
  --selectPaddingLeft: 12px;

  --selectBorderRadius: 0;

  --selectBorderColorFocus: #008c46;
  --selectBorderColorHover: #008c46;
  --selectBgColor: #fff;
  --selectBgColorFocus: #008c46;
  --selectBgColorHover: #008c46;
  --selectFontColor: #222;
  --selectFontColorHover: #fff;

  --selectValueHeight: 30px;
  --selectValueBgColor: #fff;
  --selectValueBorderColor: #d6d8d8;

  --selectPopoverTBorderWidth: 3px 0 0 0;
  --selectPopoverBBorderWidth: 0 0 3px;
  --selectPopoverBorderColor: #008c46;
  --selectPopoverTBorderRadius: 0;
  --selectPopoverBBorderRadius: 0;
  --selectPopoverTShadow: 0 -2px 10px rgba(0, 0, 0, 0.4);
  --selectPopoverBShadow: 0 2px 10px rgba(0, 0, 0, 0.4);

  --selectPopoverTTop: 1px !important;
  --selectPopoverBTop: -3px !important;

  --loadingColor: #008c46;
  --loadingColorHover: #fff;

  // Modal Window
  --modalDefaultWidth: 680px;

  --modalBackdropColor: rgba(255, 255, 255, 0.7);
  --modalBorderWidth: 3px 0 0 0;
  --modalBorderColor: #1565c0;
  --modalBorderRadius: 0;
  --modalShadow: 0 10px 20px rgba(46, 67, 110, 0.2);
  --modalMaxWidth: 1000px;
  --modalPadding: 28px 40px 18px;
  --modalTitleFont: 500 normal 17px/20px 'Roboto', sans-serif;

  // Calendar
  --calendarSelectedColor: #008c46;
  --calendarSelectedBorder: 2px solid var(--calendarSelectedColor);

  // Popover
  --popoverBorderWidth: 3px 0 0 0;
  --popoverBorderColor: #008c46;
  --popoverBorderStyle: solid;
  --popoverBorderRadius: 0;
  --popoverShadow: 0 10px 7px rgba(46, 67, 110, 0.2);
  --popoverPadding: 17px 19px;

  // Tree
  --treeDragOver: #c6e5d6;
  --treeDragOverDisabled: #f5dad6;

  // Temp variables
  --iwRadioCheckedHoverBorderColor: #00752f;
  --iwRadioHoverBackgroundColor: #eeeeee;
}
