:root {
  // TM Color palette
  --tm-white: #fff;
  --tm-red: #d65c4f;
  --tm-errorColor: #f84734;
  --tm-redLight: lighten(var(--tm-red), 10%);
  --tm-redDark: darken(var(--tm-red), 2%);
  --tm-redDarkest: darken(var(--tm-red), 10%);
  --tm-orange: #ca6500;
  --tm-orangeDark: darken(var(--tm-orange), 2%);
  --tm-yellow: #ffb106;
  --tm-yellowDark: darken(var(--tm-yellow), 2%);
  --tm-green: #008c46;
  --tm-greenDark: #067339;
  --tm-blue: #0085b2;
  --tm-blueDark: #4a84b6;
  --tm-grayLight: #adadad;
  --tm-gray: #404040;
  --tm-light-gray: #9c9c9c;
  --tm-grayDark: darken(var(--tm-gray), 2%);
  --tm-borderDefaultColor: #ddd;
  --tm-borderDefaultRadius: 5px;
  --tm-defaultLineHeight: 120%;
  --tm-buttonRed: #ea5252;

  // Simple grid
  --tm-simpleGridColor: #555;
  --tm-simpleGridThColor: var(--tm-light-gray);
  --tm-baseFontSize: 14px;
}
