html,
body {
  font-family: Roboto, sans-serif;

  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;

  letter-spacing: 0;
}

@font-face {
  font-family: Roboto;
  src: url("../assets/fonts/Roboto/roboto.regular.ttf");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: Roboto;
  src: url("../assets/fonts/Roboto/roboto.light.ttf");
  font-style: normal;
  font-weight: 200;
}

@font-face {
  font-family: Roboto;
  src: url("../assets/fonts/Roboto/roboto.medium.ttf");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: Roboto;
  src: url("../assets/fonts/Roboto/roboto.bold.ttf");
  font-style: normal;
  font-weight: bold;
}
