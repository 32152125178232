@import "./fonts";
@import "./grid";
@import "./common";
@import "./platform-theme";
@import "./platform-parsed";
@import "./platform-hacks";
@import "./inputs";
@import "./notifier";
@import "./theme";

html,
body {
  overflow: hidden;
  height: 100%;
  margin: 0;
  padding: 0;
}
h2 {
  margin: 0;
  padding: 0;

  font-weight: normal;
  font-size: 1.1rem;
  line-height: 1.4rem;
}
h2.contentHeader {
  overflow: hidden;
  display: flex;
  flex-grow: 100;
  flex-wrap: wrap;
  align-items: baseline;
  margin: 0 15px 11px 0;
  padding-top: 7px;
  color: #333;
  font-size: 1.1rem;
  font-weight: 400;
  text-overflow: ellipsis;
}
a {
  color: #007fff;
  text-decoration: none;
  outline: 0;
  cursor: pointer;
}

/*
* Scrollbar styling
*/
::-webkit-scrollbar {
  width: 11px;
  height: 11px;

  cursor: pointer;
}

::-webkit-scrollbar-button {
  width: 11px;
  height: 11px;
}

::-webkit-scrollbar-track {
  border-radius: 0;
}

::-webkit-scrollbar-thumb {
  background: #dadada;
  box-shadow: inset -1px -1px 0 rgb(0 0 0 / 5%), inset 1px 1px 0 rgb(0 0 0 / 5%);
  border: 2px solid transparent;
  background-clip: padding-box;
  border-radius: 5px;

  cursor: pointer;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

::-webkit-scrollbar-thumb:hover {
  border-color: darken(#ddd, 15%);
}

.iw-form-row-label {
  text-align: right;
}
.perimeter-entry-form .form-row .iw-form-row-label {
  width: 65px;
}

[hidden] {
  display: none !important;
}
input[type="number"]::-webkit-inner-spin-button {
  appearance: none;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+PHN2ZyB3aWR0aD0iN3B4IiBoZWlnaHQ9IjE3cHgiIHZpZXdCb3g9IjAgMCA3IDE3IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPiAgICAgICAgPHRpdGxlPkdyb3VwIDM8L3RpdGxlPiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4gICAgPGRlZnM+PC9kZWZzPiAgICA8ZyBpZD0iYXR0cmlidXRlc19kaWZmIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4gICAgICAgIDxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0zNzcuMDAwMDAwLCAtNDA2LjAwMDAwMCkiIGlkPSJHcm91cC0zIiBmaWxsPSIjMTExMTExIj4gICAgICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgzNzcuMDAwMDAwLCA0MDYuMDAwMDAwKSI+ICAgICAgICAgICAgICAgIDxwb2x5Z29uIGlkPSJUcmlhbmdsZSIgcG9pbnRzPSIwIDEzIDcgMTMgMy41IDE3Ij48L3BvbHlnb24+ICAgICAgICAgICAgICAgIDxwb2x5Z29uIGlkPSJUcmlhbmdsZSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMy41MDAwMDAsIDIuMDAwMDAwKSByb3RhdGUoMTgwLjAwMDAwMCkgdHJhbnNsYXRlKC0zLjUwMDAwMCwgLTIuMDAwMDAwKSAiIHBvaW50cz0iMCAwIDcgMCAzLjUgNCI+PC9wb2x5Z29uPiAgICAgICAgICAgIDwvZz4gICAgICAgIDwvZz4gICAgPC9nPjwvc3ZnPg==");
  background-repeat: no-repeat;
  background-position: center;
  width: 25px;
  opacity: 1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
}
input[type="time"]::-webkit-clear-button,
input[type="time"]::-webkit-inner-spin-button {
  display: none;
}
:root .iw-input-container.error:hover {
  border-color: transparent;
  background-color: transparent;
}

hr {
  border: 0;
  border-top: 1px solid #eee;
}
